<template>
    <div class="calendar">
        <div class="calendar__top">
            <Legend horizontal />
        </div>
        <Calendar :events="events"
                  :days="days"
                  :isISOWeek="config.locale.is_iso_week"
                  @monthChange="onMonthChange"
                  :loading="loading"
                  :date="date">
            <template v-slot:event="{ event }">
                <router-link
                    class="event"
                    :class="{
                        'event--past': event._continuing === 0,
                        'event--between': event._continuing === 1,
                        'event--future': event._continuing === 2
                    }"
                    target='_blank'
                    :aria-label="ariaForEvent(event)"
                    :to="{ name: event.event_type === 0 ? 'incident' : 'maintenance', params: { id: event.event_id }}"
                >
                    <StatusDot :status="event.status" />
                    <span
                        class="title"
                        :class="`service-${event.service_id}`"
                    >
                        {{serviceFullName(event)}}
                    </span>
                    <div class="maintenance">
                        <Icon v-if="event.event_type === 1" class="maintenance__icon" name="maintenance" />
                    </div>
                </router-link>
            </template>
            <template v-slot:show-more="{ events }">
                <div class="events">
                    <div class="event event--inPopover"
                        v-for="event in events" :key="event.entry_id"
                    >
                        <StatusDot :status="event.status" />
                        <router-link
                            target='_blank'
                            class="title"
                            :to="{ name: event.event_type === 0 ? 'incident' : 'maintenance', params: { id: event.event_id }}"
                        >
                            {{serviceName(event)}}
                        </router-link>
                    </div>
                </div>
            </template>
        </Calendar>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Calendar from '@/components/Calendar.vue'
import { DateTime } from 'luxon'
import Icon from '@/components/Icon.vue'
import StatusDot from '@/components/StatusDot.vue'
import Legend from '@/components/Legend.vue'
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import progress from '@/mixins/progress'
import EventBus from '@/event-bus';

export default {
    name: 'CalendarView',
    mixins: [connectedHubsGrid, progress],
    components: {
        Calendar,
        Icon,
        Legend,
        StatusDot
    },
    data() {
        return {
            displayed: false
        }
    },
    computed: {
        ...mapGetters({
            config: 'config',
            events: 'calendar/events',
            days: 'calendar/days',
            date: 'calendar/date'
        })
    },
    methods: {
        ...mapActions({
            fetch: 'calendar/fetch',
            pushMessage: 'messages/push'
        }),
        async onMonthChange(date) {
            try {
                await this.progress(() => this.fetch({ from: date }))
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-history'))
            }
        },
        ariaForEvent(event) {
            return this.$t('history.calendar.aria-event', {
                type: `${event.event_type === 0 ? `Incident on` : `Maintenance of`}`,
                name: this.serviceName(event),
                from: event.start_formatted,
                to: event.end_formatted
            })
        },
        serviceFullName(event) {
            if (this.config.service_name_first_in_calendar) {
                return `${this.serviceName(event)}, ${event.group_name}`
            } else {
                return `${event.group_name}, ${this.serviceName(event)}`
            }
        }
    },
    mounted() {
        (async () => {
            const date = new Date()
            const newDate = DateTime.fromJSDate(date)
                                    .startOf('month')
                                    .plus({ days: 15 })
            await this.onMonthChange(newDate.toJSDate())
            EventBus.$emit('calendarDataMounted')
        })()
    }
}
</script>
<style lang="scss" scoped>
.events {
    padding: 16px;
    padding-top: 32px;
}
.calendar {
    width: 100%;
    box-sizing: border-box;

    ::v-deep(.fc-event) {
        border: none;
        background-color: #f1f1f1;
    }

    &__top {
        display: flex;
        justify-content: center;
    }
}

.event {
    display: flex;
    padding: 4px 4px;
    background-color: rgba(194, 204, 220, 0.5);
    border-radius: 6px;
    transition: background-color .3s;
    cursor: pointer;
    text-decoration: none;
    align-items: center;

    &--past {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--between {
        border-radius: 0;
    }

    &--future {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .title {
        margin-left: 4px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #000;
    }

    &--inPopover {
        background-color: transparent;
        border-bottom: none;

        .title {
            color: $textColor;
            white-space: normal;
            margin-left: 16px;

            &:hover {
                color: $textColor2;
            }
        }
    }

    .maintenance {
        height: 18px;
        width: 18px;
        margin-left: auto;

        &__icon {
            height: 18px;
            width: 18px;
        }
    }
}

.events {
    overflow: auto;
    max-width: 300px;
    max-height: 300px;
}
</style>
