<template>
    <div class="notifications">
        <div class="notifications__header">
            <h2 class="notifications__header-text">{{ $t('summary.notifications.label') }}</h2>
        </div>
        <template v-if="(incidents.length || maintenances.length)">
            <Incident
                v-for="incident in incidents"
                :key="`i-${incident.id}`"
                :incident="incident"
            />
            <Maintenance
                v-for="(maintenance, index) in maintenances"
                :key="`m-${maintenance.id}`"
                :maintenance="maintenance"
            />
        </template>
        <NoEventsBox :text="$t('summary.notifications.no-events')" v-else />
    </div>
</template>
<script>
import Incident from './notification/Incident.vue'
import Maintenance from './notification/Maintenance.vue'
import NoEventsBox from './NoEventsBox.vue'
import EventsList from '@/views/hub/components/events/EventsList.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        EventsList,
        Incident,
        Maintenance,
        NoEventsBox
    },
    computed: {
        ...mapGetters({
            config: 'config',
            incidents: 'incidents/current',
            maintenances: 'maintenances/current'
        })
    }
}
</script>
<style lang="scss" scoped>
.notifications {
    @include breakpoint($lg) {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    &__header {
        padding: 16px;
    }

    &__header-text {
        font-weight: 600;
        font-size: 1.125rem;
        color: #29394A;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

</style>
