import api from '@/api-client';
import { dateAsISOTime } from '@/utils';
import EventBus from '@/event-bus';

export default {
    async fetch({ commit }, id) {
        const { data: maintenance } = await api.get(`maintenances/${id}`);
        commit('setMaintenances', [maintenance]);
        EventBus.$emit('new-content-loaded')
        return maintenance
    },

    async fetchCurrent({ commit }) {
        const { data: maintenances } = await api.get(`maintenances`);
        commit('setMaintenances', maintenances);
        commit('setCurrentMaintenances', maintenances);
        EventBus.$emit('new-content-loaded')
        return maintenances
    },

    async fetchCalendarMaintenances({ commit, getters }, date = getters.date) {
        commit('resetCalendarMaintenancesDays');
        const { data: maintenances } = await api.get(`maintenances_calendar?date=${dateAsISOTime(date)}`);
        commit('setCalendarMaintenances', maintenances);
        commit('setDate', date)
        EventBus.$emit('new-content-loaded')
        return maintenances
    },

    async fetchFutureMaintenances({ commit }) {
        const { data: maintenances } = await api.get(`future_maintenances`);
        commit('setFutureMaintenances', maintenances);
        commit('setMaintenances', maintenances);
        EventBus.$emit('new-content-loaded')
        return maintenances
    },

    async fetchPlannedMaintenances({ dispatch, rootGetters }) {
        dispatch('fetchCalendarMaintenances')
        dispatch('fetchFutureMaintenances')
    },
    resetDate({ commit }) {
        commit('setDate', new Date())
    }
}
