import EventBus from '@/event-bus';
import { hideAllPoppers } from 'floating-vue'

export default {
    mounted() {
        EventBus.$on('historyTabSwitch', () => {
            hideAllPoppers()
        })
    }
}
