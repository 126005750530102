<template>
    <div class="text-expander">
        <div
            class="text--wrapper"
            :class="{ 'text--short': isCollapsed }"
            :style="{
                'line-clamp': (isCollapsed) ? visibleLines : 'none'
            }"
            ref="content"
        >
            <p
                class="text"
                v-html="text"
                :data-key="id"
            >
            </p>
        </div>
        <div
            v-if="needCollapsing && showMoreEnabled"
            class="expander">
            <span
                v-if="!expanded"
                class="indicator"
            >
                ...
            </span>
            <span
                @click="toggle"
                class="show-more"
            >
                {{ !expanded ? showMoreLabel : $t('summary.show-less') }}
            </span>
        </div>
    </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
    props: {
        id: {
            type: [Number, String],
            default: undefined
        },
        showMoreEnabled: {
            type: Boolean,
            default: true
        },
        showMoreLabelKey: {
            type: String,
            default: 'summary.show-more'
        },
        text: {
            type: String,
            required: true,
            default: ''
        },
        visibleLines: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            expanded: false,
            needCollapsing: false
        }
    },
    computed: {
        isCollapsed() {
            return this.showMoreEnabled && !this.expanded
        },
        showFullContent() {
            return !this.showMoreEnabled || this.expanded
        },
        showMoreLabel() {
            this.onNewContentLoaded()
            return this.$t(this.showMoreLabelKey)
        }
    },
    mounted() {
        this.onNewContentLoaded()
        EventBus.$on('new-content-loaded', () => this.onNewContentLoaded())
    },
    methods: {
        onNewContentLoaded() {
            if (this.text.length === 0) {
                return
            }

            const el = this.$refs.content
            if (el == null) {
                return
            }

            if (this.showMoreEnabled === false) {
                this.expanded = false;
                return;
            }

            if (el.scrollHeight > el.clientHeight) {
                this.needCollapsing = true
                this.expanded = false
            }
        },
        toggle() {
            this.expanded = !this.expanded
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/views/events.scss';

.text-expander {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $textColor;
    font-size: 0.8125rem;
    word-break: break-word;
    font-weight: 350;
    line-height: 1.5;

    .text--wrapper {
    }

    .expander {
        display: flex;
    }

    @include quill-styles(13px);

    .text {
        height: auto;
        margin-block-start: 12px;
        margin-block-end: 12px;
        position: relative;

        &--short {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &.hidden {
            display: none;
        }
    }

    .expander {
        display: flex;

        .indicator {
            margin-right: 8px;
        }

        .show-more {
            cursor: pointer;
            color: $textColor2;
            font-weight: 500;
        }
    }
}
</style>
