export default {
    setMaintenances(state, maintenances) {
        maintenances.forEach(maintenance => {
            state.maintenances[maintenance.id] = maintenance
        })
    },
    setCurrentMaintenances(state, maintenances) {
        state.currentMaintenanceIds = maintenances.map(maintenance => maintenance.id)
    },
    setCalendarMaintenances(state, maintenances) {
        state.calendarMaintenances = maintenances
    },
    resetCalendarMaintenancesDays(state) {
        state.calendarMaintenances.days = {}
    },
    setFutureMaintenances(state, maintenances) {
        state.futureMaintenanceIds = maintenances.map(maintenance => maintenance.id)
    },
    setDate(state, date) {
        state.date = date
    }
}
