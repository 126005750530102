<template>
    <div class="calendar">
        <div class="header">
            <div class="header__label" :aria-label="`${$t('components.calendar.aria-header')} ${label}`">
                {{ label }}
            </div>
            <div class="header__navigation">
                <div class="header__navigation__today">
                    <Button class="button button-today" :disabled="loading" @click="back">{{ $t('components.calendar.today') }}</Button>
                </div>
                <div class="header__navigation__weeks">
                    <Button
                        class="button-prev"
                        icon="chevron-left"
                        :disabled="loading || noHistoryAvailable"
                        @click="previous"
                    >
                        {{ $t('components.calendar.previous') }}
                    </Button>
                    <Button class="button-next" icon="chevron-right" :content-reverse="true" :disabled="loading" @click="next">{{ $t('components.calendar.next') }}</Button>
                </div>
            </div>
        </div>
        <table class="table">
            <tr class="table__row table__row--headers">
                <th class="table-header"
                    v-for="(header, index) in headers()"
                    :key="index"
                    :id="`dow-${index}`"
                >
                    {{ header }}
                </th>
            </tr>
            <tr class="table__row"
                v-for="(row, rowNumber) in gridRows"
                :key="rowNumber"
                :class="{
                    'table__row--backgrounds': row.isBackground,
                    'table__row--headers': row.tag === 'th' && !row.isBackground,
                    'table__row--content': row.tag === 'td' && !row.isShowMore,
                    'table__row--show-more': row.isShowMore
                }"
                :aria-hidden="row.isBackground"
                :style="{ top: calculateRowTopPosition(row) }"
            >
                <component :is="row.tag"
                           v-for="(cell, cellIndex) in row.items"
                           :key="cellIndex"
                           class="cell"
                           :colspan="cell.length || 1"
                           :id="row.tag === 'th' && !row.isBackground
                                ? `dom-${cell.number}`
                                : undefined
                            "
                           :headers="row.tag === 'td' && !row.isShowMore
                                ? getCellHeaders(row.items, cellIndex, row.days)
                                : undefined"
                >
                    <div v-if="row.tag === 'th' && !row.isBackground"
                         class="cell__header"
                         :class="{
                            'cell__header--other-month': cell.otherMonth,
                            'cell__header--no-top-spacing': rowNumber === 1
                        }"
                    >
                        {{ cell.number }}
                    </div>
                    <div v-else-if="row.isShowMore" class="cell__show-more show-more">
                        <template v-if="cell > 0">
                            <VDropdown class="events-popover"
                                @hide="onClose"
                                @apply-show="onPopoverShow"
                            >
                                <a href="#calendar-show-more"
                                   :aria-label="$t('components.calendar.aria-show-more', { count: cell })"
                                   class="show-more__label"
                                >
                                    {{ $t('components.calendar.show-more') }} ({{ cell }})
                                </a>
                                <template #popper>
                                    <Popover :focus-trap="focusTrap">
                                        <slot
                                            name="show-more"
                                            v-bind:events="getEvents(cellIndex + 1, row.gridRowIndex)"
                                        />
                                    </Popover>
                                </template>
                            </VDropdown>
                        </template>
                    </div>
                    <div v-else
                         class="cell__content"
                    >
                        <template v-if="cell.event">
                            <slot name="event" :event="cell.event">
                                {{ cell.event.title }}
                            </slot>
                        </template>
                    </div>
                </component>
            </tr>
        </table>
    </div>
</template>

<script>
import Button from './Button.vue'
import calendar from '@/mixins/calendar'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex';
import Popover from '@/views/hub/components/history/calendar/CalendarPopover.vue'
import { useMq } from 'vue3-mq'

export default {
    components: {
        Button,
        Popover
    },
    mixins: [calendar],
    data() {
        return {
            focusTrap: false,
            mq: useMq()
        }
    },
    props: {
        events: {
            type: Array,
            default: () => []
        },
        days: {
            type: Array,
            default: () => []
        },
        displayLimit: {
            type: Number,
            default: 3
        },
        isISOWeek: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        date: {
            type: Date,
            default: () => new Date()
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        label() {
            const tz = this.$cookies.get('timezone') || this.config.time_zone_name
            const localNow = DateTime.fromJSDate(this.date).setZone(tz)
            const monthNames = this.$tm('components.calendar.months')
            return monthNames[localNow.month - 1] + ' ' + localNow.year;
        },
        luxonDate() {
            return DateTime.fromJSDate(this.date)
        },
        cellHeight() {
            return this.displayLimit < 1 ? '100px' : `${this.displayLimit * 30 + 100}px`
        },
        grid() {
            const generateMatrix = (rowsLength) => {
                return Array.from({ length: rowsLength }, () => {
                    return Array.from({ length: 7 }, (_, i) => ({ capacity: 7 - i }))
                })
            }

            const insertEventsIntoMatrix = (matrix, events) => {
                const updateCapacities = (row, cell, length) => {
                    row.slice(cell, cell + length).map(slot => {
                        slot.capacity = 0
                    })

                    let freeSlots = 1
                    for (let i = cell - 1; i >= 0; i--) {
                        if (row[i].capacity === 0) break
                        row[i].capacity = freeSlots
                        freeSlots++;
                    }
                }

                let continuingEvents = []
                events.forEach(event => {
                    const cell = event.cell % 7
                    let length = event.length

                    if (cell + event.length > 7) {
                        length = 7 - cell

                        event._continuing = '_continuing' in event ? 1 : 2 // 0 - |-->, 1 <-->, 2 -->|

                        continuingEvents = [...continuingEvents, {
                            ...event,
                            length: event.length - length,
                            cell: 0,
                            _continuing: 0
                        }]
                    }

                    for (let i = 0; i < matrix.length; i++) {
                        const slot = matrix[i][cell]
                        if (slot.capacity >= length) {
                            slot.event = event
                            slot.length = length

                            updateCapacities(matrix[i], cell, length)
                            break;
                        }
                    }
                })

                return {
                    matrix: matrix.map(row => row.filter(slot => slot.event || slot.capacity)),
                    continuingEvents
                }
            }

            let grid = []
            let continuingEvents = []
            let i = 0
            while (i < this.days.length) {
                const eventsInDaysRange = [...continuingEvents, ...this.events.filter(event => event.cell >= i && event.cell < i + 7)]
                const { matrix, continuingEvents: events } = insertEventsIntoMatrix(generateMatrix(eventsInDaysRange.length), eventsInDaysRange)
                continuingEvents = events
                grid = [...grid, {
                    hash: Math.random().toString(36).substring(7),
                    days: this.days.slice(i, i + 7),
                    matrix
                }]
                i += 7
            }
            return grid
        },
        gridRows () {
            let rows = []
            this.grid.forEach((row, rowIndex) => {
                rows.push({
                    tag: 'th',
                    items: row.days,
                    gridRowIndex: rowIndex,
                    isBackground: true
                })
                rows.push({
                    tag: 'th',
                    items: row.days
                })
                for (let i = 0; i < this.displayLimit; i++) {
                    rows.push({
                        tag: 'td',
                        items: row.matrix[i],
                        days: row.days
                    })
                }
                const showMoreArray = []
                row.days.forEach((_, dayIndex) => {
                    showMoreArray.push(
                        this.getHiddenEventsCount(dayIndex + 1, rowIndex)
                    )
                })
                rows.push({
                    tag: 'td',
                    items: showMoreArray,
                    hash: row.hash,
                    gridRowIndex: rowIndex,
                    isShowMore: true
                })
            })
            return rows
        },
        noHistoryAvailable() {
            return this.config.no_previous_history === true &&
                (DateTime.now().startOf('month') >=
                 this.luxonDate.startOf('month'))
        }
    },
    methods: {
        getEvents(cell, row) {
            const index = row * 7 + cell - 1

            return this.events.filter(event => index >= event.cell && index < event.cell + event.length)
        },
        getHiddenEventsCount(cell, row) {
            return this.getEvents(cell, row).length - this.displayLimit
        },
        next() {
            this.$emit('monthChange', this.addMonths(this.date, 1))
        },
        onPopoverShow() {
            this.focusTrap = true
        },
        onClose() {
            this.focusTrap = false
            // this.$refs.button.focus()
        },
        previous() {
            this.$emit('monthChange', this.addMonths(this.date, -1))
        },
        addMonths(date, num) {
            const newDate = DateTime.fromJSDate(date)
                .plus({ months: num })
                .startOf('month')
                .plus({ days: 15 })
            return newDate.toJSDate()
        },
        back() {
            this.$emit('monthChange', new Date())
        },
        getCellHeaders (slots, slotIndex, days) {
            let headers = ''
            const slotLengths = slots.map((slot) => slot.length || 1)
            let startIndex = [...slotLengths]
                .splice(0, slotIndex)
                .reduce((a, b) => a + b, 0)
            const endIndex = startIndex + slotLengths[slotIndex]
            for (let i = startIndex; i < endIndex; i++) {
                headers += `dow-${i} `
                headers += `dom-${days[i].number} `
            }
            return headers
        },
        calculateRowTopPosition (row) {
            if (!row.isBackground) { return }
            let cellHeightNumber = parseInt(this.cellHeight.replace('px', '')) + 3
            if (this.mq.current === 'xs' || this.mq.current === 'sm') {
                cellHeightNumber += 12
            }
            const tableHeaderHeight = 58
            const topPosition = row.gridRowIndex * cellHeightNumber + tableHeaderHeight

            return `${topPosition}px`
        }
    }
}
</script>

<style lang="scss" scoped>
$border: 1px solid silver;
$cellWidth: calc(100%/7 - 1px);

.calendar {
    .header {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include breakpoint($md) {
            height: 40px;
        }

        &__label {
            font-size: 20px;
            align-self: center;
            text-align: center;
        }

        &__navigation {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            @include breakpoint($md) {
                margin-top: 0;
            }

            &__today {
                @include breakpoint($md) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .button-today {
                    @include button-rounded();

                    padding-left: 16px;
                    padding-right: 16px;
                }
            }

            &__weeks {
                display: flex;
                flex-direction: row;

                @include breakpoint($md) {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .button-next {
                    @include button-rounded();

                    padding-left: 16px;
                    padding-right: 48px;

                    @include breakpoint($md) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }

                .button-prev {
                    @include button-rounded();

                    padding-left: 48px;
                    padding-right: 16px;

                    @include breakpoint($md) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
    .table {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 22px;
        table-layout: fixed;
        border-collapse: collapse;
        position: relative;

        &__row {
            position: relative;

            .table-header {
                border: $border;
                border-left: none;
                width: $cellWidth;
                color: $textColor2;
                font-weight: 300;
                padding: 8px;
                background: #F9FAFC;
                height: 40px;
                text-align: left;

                &:nth-child(1) {
                    border-left: $border;
                }
            }

            .day {
                &--today {
                    background: rgba(0, 102, 204, 0.1);
                }
            }

            &--content {
                height: 34px;
            }

            &--show-more {
                height: 46px;

                @include breakpoint($md) {
                    height: 34px;
                }

                .cell {
                    padding-top: 3px;
                    vertical-align: top;
                }
            }

            &--backgrounds {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;

                .cell {
                    display: block;
                    width: calc(100% / 7);
                    height: 202px;
                    border: $border;
                    border-top: none;

                    &:not(:first-child) {
                        border-left: none;
                    }

                    @include breakpoint($md) {
                        height: 190px;
                    }
                }
            }

            .cell {
                background-color: transparent;

                &__header {
                    text-align: right;
                    padding: 8px;
                    margin-top: 16px;

                    &--other-month {
                        color: silver;
                    }

                    &--no-top-spacing {
                        margin-top: 0;
                    }
                }

                &__content {
                    padding: 1px;
                }

                .show-more {
                    text-align: center;

                    &__label {
                        font-size: 12px;
                        font-weight: bold;
                        cursor: pointer;
                        color: $textColor;
                        transition: color 0.3s;
                        text-decoration: none;

                        &:hover {
                            color: $textColor2;
                        }
                    }
                }
            }
        }
    }
}
</style>
