<template>
    <div class="planned-maintenances">
        <div class="header" v-if="config.maintenance_list_mode !== 2">
            <h2 class="header__text">{{ $t('summary.planned-maintenance.label') }}</h2>
        </div>
        <DatePicker
            v-if="futureEventsExist && config.maintenance_list_mode === 0"
            :events="maintenances"
            :today="today"
            :isISOWeek="config.locale.is_iso_week"
            :onDateChange="dateChange"
            @dayClick="onDayClick"
            timestamp-key-from="timestamp_start"
            timestamp-key-to="timestamp_end"
        >
            <template v-slot:day="{ day }">
                <div v-if="day.events.length">
                    <VDropdown class="popover maintenance-popover"
                        @hide="onClose(day.dayID)"
                        @apply-show="onPopoverShow(day.dayID)"
                    >
                        <a
                            href="#"
                            class="circle"
                            v-on:click.prevent
                            :ref="`datepicker_${day.dayID}`"
                        >
                            <span>{{ day.day }}</span>
                        </a>
                        <template #popper>
                            <MaintenancesPopover
                                :maintenances="day.events"
                                :focus-trap="focusTraps[day.dayID] || false"/>
                        </template>
                        </VDropdown>
                </div>
                <span v-else>{{ day.day }}</span>
            </template>
        </DatePicker>
        <PlannedMaintenancesList
            v-else-if="futureEventsExist && config.maintenance_list_mode === 1"
            :maintenances="maintenances"
            :paginated="config.planned_maintenance_list_pagination"
        />
        <NoEventsBox
            v-else-if="config.maintenance_list_mode !== 2"
            :text="$t('summary.planned-maintenance.no-events')"
        />
    </div>
</template>
<script>
import Maintenance from './notification/Maintenance.vue'
import Status from '@/components/StatusDot.vue';
import DatePicker from './planned-maintenances/DatePicker.vue'
import PlannedMaintenancesList from './planned-maintenances/PlannedMaintenancesList.vue'
import MaintenancesPopover from '@/views/hub/components/history/list/group/popovers/MaintenancesPopover.vue'
import NoEventsBox from './NoEventsBox.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        Maintenance,
        Status,
        DatePicker,
        MaintenancesPopover,
        PlannedMaintenancesList,
        NoEventsBox
    },
    computed: {
        ...mapGetters({
            config: 'config',
            maintenances: 'maintenances/planned',
            futureEventsExist: 'maintenances/futureEventsExist',
            today: 'maintenances/today'
        })
    },
    data() {
        return {
            focusTraps: {},
            openPopovers: {},
            popovers: {}
        }
    },
    methods: {
        ...mapActions({
            fetch: 'maintenances/fetch',
            fetchCalendarMaintenances: 'maintenances/fetchCalendarMaintenances',
            pushMessage: 'messages/push'
        }),
        onClose(dayID) {
            const wasFocussed = this.focusTraps[dayID]
            this.focusTraps[dayID] = false
            delete this.openPopovers[dayID]
            if (!wasFocussed) return

            const el = this.$refs[`datepicker_${dayID}`]
            if (!el) return

            el.focus()
        },
        onPopoverShow(dayID) {
            this.focusTraps[dayID] = true
            this.openPopovers[dayID] = true
        },
        async onDayClick(day) {
            this.popovers[day.dayID] = true;
            try {
                await Promise.all(day.events.map(({ id }) => this.fetch(id)));
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-maintenances'))
            }
        },
        async dateChange(date) {
            try {
                return await this.fetchCalendarMaintenances(date)
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-maintenances'))
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.planned-maintenances {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    a:hover, a:visited, a:link, a:active {
        text-decoration: none;
    }

    @include breakpoint($lg) {
        margin-top: 32px;
    }

    .header {
        padding: 16px;

        &__text {
            font-weight: 600;
            font-size: 1.125rem;
            color: #29394A;
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }

    .icon {
        color: $maintenance-color;
    }

    .day {
        &--event {
            .circle {
                background-color: $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                margin: auto;
                color:#fff;
                line-height: 2.25rem;
                text-align: center;
                cursor: pointer;
            }
        }

        &--past.day--event {
            .circle {
                background-color: $textColor;
            }
        }
    }
}
</style>
